import React, { Component } from "react"
import { Link } from "gatsby"

class BlogSideBar extends Component {
  render() {
    const { blog } = this.props

    return (
      <div>
        <span>
          <Link key={blog.slug + "1"} to={`/${blog.slug}`}>
            <img
              src={blog.blogImage.file.url}
              alt="blogimage"
              className="img-rounded"
            />
          </Link>
          <Link key={blog.slug + "a"} to={`/${blog.slug}`}>
            <span>{blog.blogTitle}</span>
            <span className="meta">{blog.blogPublishDate}</span>
          </Link>
        </span>
      </div>
    )
  }
}

export default BlogSideBar
